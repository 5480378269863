<template>
  <v-dialog
    v-model="isOpen"
    persistent
    :min-width="smAndDown ? 100 : 500"
    width="600"
    transition="dialog-top-transition"
  >
    <v-card class="bg-white">
      <v-card-title
        style="background-color: #0299781a"
        class="d-flex pa-2 justify-center w-full align-center text-primary"
      >
        <span class="font-weight-bold">{{
          isEditing
            ? $t('dashboard.time-slot.dialogVacation.titleEdit')
            : $t('dashboard.time-slot.dialogVacation.title')
        }}</span>
        <img
          style="position: absolute; right: 10px"
          class="cursor-pointer mr-2"
          @click="() => handleClose()"
          src="@/assets/_close.svg"
        />
      </v-card-title>

      <v-form ref="form" v-model="isFormValid">
        <v-card-text class="d-flex flex-column ga-5 px-4 px-sm-10 pt-5">
          <div>
            <span class="text-teal font-weight-bold">{{
              $t('dashboard.time-slot.dialogVacation.from')
            }}</span>
            <div class="d-sm-flex align-center ga-2 w-100 mt-2">
              <div class="w-sm-66 w-100 mb-2 mb-sm-0">
                <span class="text-gray-80">{{
                  $t('dashboard.time-slot.dialogVacation.startDate')
                }}</span>
                <v-date-input
                  prepend-icon=""
                  class="w-100 rounded-lg border"
                  ref="startDatePicker"
                  v-model="startDate"
                  :append-inner-icon="'mdi-calendar-remove'"
                  :placeholder="
                    $t('dashboard.time-slot.dialogVacation.datePlaceholder')
                  "
                  density="compact"
                  rounded="lg"
                  hide-details="auto"
                  hide-actions
                  flat
                  variant="outlined"
                  :min="today"
                />
              </div>

              <div class="w-sm-33 w-100">
                <span class="text-gray-80">{{
                  $t('dashboard.time-slot.dialogVacation.startTime')
                }}</span>
                <SelectHourMinute
                  class="d"
                  v-model:hour="startHour"
                  v-model:minute="startMinute"
                />
              </div>
            </div>
            <span v-if="errors.startDate" class="text-error text-caption">{{
              errors.startDate
            }}</span>
          </div>

          <div>
            <span class="text-teal font-weight-bold">{{
              $t('dashboard.time-slot.dialogVacation.to')
            }}</span>
            <div class="d-sm-flex align-center ga-2 w-100 mt-2">
              <div class="w-sm-66 w-100 mb-2 mb-sm-0">
                <span class="text-gray-80">{{
                  $t('dashboard.time-slot.dialogVacation.endDate')
                }}</span>
                <v-date-input
                  prepend-icon=""
                  class="w-100 endDate"
                  ref="endDatePicker"
                  v-model="endDate"
                  :append-inner-icon="'mdi-calendar-remove'"
                  :placeholder="
                    $t('dashboard.time-slot.dialogVacation.datePlaceholder')
                  "
                  density="compact"
                  rounded="lg"
                  hide-details="auto"
                  hide-actions
                  variant="outlined"
                  :min="today"
                />
              </div>

              <div class="w-sm-33 w-100">
                <span class="text-gray-80">{{
                  $t('dashboard.time-slot.dialogVacation.endTime')
                }}</span>
                <SelectHourMinute
                  class="d"
                  v-model:hour="endHour"
                  v-model:minute="endMinute"
                />
                <span
                  v-if="errors.endDateTime"
                  class="text-error text-caption"
                  >{{ errors.endDateTime }}</span
                >
              </div>
            </div>
            <span v-if="errors.endDate" class="text-error text-caption">{{
              errors.endDate
            }}</span>
          </div>

          <!-- <VAlert
            v-if="showAlert"
            :text="String(alertText)"
            color="red"
            variant="outlined"
            class="flex-grow-1 red-icon"
            closable
          /> -->

          <div class="w-100 d-sm-flex ga-sm-2 ga-0 mt-5">
            <template v-if="isEditing">
              <v-btn
                class="w-sm-50 w-100 mb-2 mb-sm-0"
                color="red-darken-1"
                :loading="loadingDelete"
                @click="openDeleteConfirm"
              >
                {{ $t('dashboard.time-slot.delete') }}
              </v-btn>
            </template>

            <v-btn
              v-else
              @click="handleClose"
              color="#E0E0E0"
              class="w-sm-50 w-100 mb-2 mb-sm-0"
            >
              <span style="color: #757575; font-weight: 700">{{
                $t('dashboard.time-slot.dialogVacation.cancelBtn')
              }}</span>
            </v-btn>
            <v-btn
              :loading="loadingConfirmBtn"
              @click="() => toggleVerifyDialog()"
              color="secondary"
              class="w-sm-50 w-100"
            >
              {{
                isEditing
                  ? `${$t('dashboard.time-slot.dialogVacation.save')}`
                  : `${$t('dashboard.time-slot.dialogVacation.create')}`
              }}
            </v-btn>
          </div>
        </v-card-text>
      </v-form>

      <v-card-actions
        v-if="false"
        class="pr-7 pl-5 ga-4 d-flex justify-end align-center pb-8"
      >
        <v-btn
          v-if="isEditing"
          class="px-6"
          variant="outlined"
          color="red-darken-1"
          :loading="loadingDelete"
          @click="openDeleteConfirm"
        >
          {{ $t('dashboard.time-slot.delete') }}
        </v-btn>

        <v-btn
          :disabled="!validDates || !!errorDate"
          class="px-6"
          variant="flat"
          color="blue-darken-1"
          :loading="loadingConfirmBtn"
          @click="toggleVerifyDialog"
        >
          {{ $t('dashboard.time-slot.save') }}
        </v-btn>
      </v-card-actions>

      <div
        v-if="false"
        class="datetime-selector"
        :class="{
          'd-flex align-center flex-wrap ga-2': !smAndDown,
          'd-flex flex-column ga-2': xs,
        }"
      >
        <div>
          <VIcon icon="mdi-calendar-badge" class="a mr-2" />
          <span class="b">{{
            $t('dashboard.time-slot.dialogVacation.from')
          }}</span>
        </div>
        <SelectDayMonthYear
          class="c"
          v-model:day="startDay"
          v-model:month="startMonth"
          v-model:year="startYear"
        />
        <SelectHourMinute
          class="d"
          v-model:hour="startHour"
          v-model:minute="startMinute"
        />
      </div>
      <div
        v-if="false"
        class="datetime-selector"
        :class="{
          'd-flex align-center flex-wrap ga-2': !smAndDown,
          'd-flex flex-column ga-2': xs,
        }"
      >
        <div>
          <VIcon icon="mdi-calendar-badge" class="a mr-2" />
          <span class="b">{{
            $t('dashboard.time-slot.dialogVacation.to')
          }}</span>
        </div>
        <SelectDayMonthYear
          class="c"
          v-model:day="endDay"
          v-model:month="endMonth"
          v-model:year="endYear"
        />
        <SelectHourMinute
          class="d"
          v-model:hour="endHour"
          v-model:minute="endMinute"
        />
      </div>
    </v-card>

    <AlertDialog
      v-if="displayAlert"
      :dialog="displayAlert"
      @update:cancel="displayAlert = false"
      @update:confirm="() => confirmation()"
      :title="alertTitle"
      :minTitle="minTitle"
      :description="alertDescription"
      :warningColor="warningColor"
    />
  </v-dialog>
</template>

<script lang="ts" setup>
import createHttpClient from '@/api/httpClient'
import { ref, Ref, computed, watch } from 'vue'
import SelectDayMonthYear from './SelectDayMonthYear.vue'
import SelectHourMinute from './SelectHourMinute.vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { ApiResponse } from '@/api/api'
import MessageService from '../feedback/message/messageService'
import EventBus from '@/utils/eventBus'
import { VacationEditionData, VacationData } from '@/store/vacation/vacation'
import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import { useTimeSlotStore } from '@/store/timeslot/timeSlotStore'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import CalendarXIcon from '../icons/CalendarXIcon.vue'
import AlertDialog from '../dialog/AlertDialog.vue'
import { VDateInput } from 'vuetify/labs/VDateInput'

const { t } = useI18n()
const { smAndDown, xs } = useDisplay()
const isEditing = ref(false)
const isOpen = ref(false)
const displayAlert = ref(false)
const startDate = ref(null)
const startDateTime = ref(null)
const endDate = ref(null)
const endDateTime = ref(null)
const startDatePicker = ref(null)
const endDatePicker = ref(null)
const today = new Date().toISOString().split('T')[0] // Date du jour

const alertTitle = ref('')
const alertDescription = ref('')
const warningColor = ref('#12C29B')
const minTitle = ref('')

EventBus.addListener('toggleVacationDialog', () => {
  isOpen.value = !isOpen.value
})
const vacationId = ref('')
EventBus.addListener('updateVacation', (data: VacationData) => {
  isEditing.value = true
  isOpen.value = true
  vacationId.value = data.id
  startDate.value = dayjs(data.startDate, 'YYYY-MM-DD HH:mm').toDate()
  startDateTime.value = dayjs(data.startDate, 'YYYY-MM-DD HH:mm')
  endDate.value = dayjs(data.endDate, 'YYYY-MM-DD HH:mm').toDate()
  endDateTime.value = dayjs(data.endDate).format('HH:mm')
  startHour.value = data.startHour
  startMinute.value = data.startMinute
  endHour.value = data.endHour
  endMinute.value = data.endMinute
})

const openStartDatePicker = () => {
  startDatePicker.value?.fp?.open()
}

const openEndDatePicker = () => {
  endDatePicker.value?.fp?.open()
}

const reset = () => {
  errors.value = {}
  isOpen.value = false
  isEditing.value = false
  isDeleting.value = false
  startDate.value = null
  startDateTime.value = null
  endDate.value = null
  endDateTime.value = null
  verifyVacationText.value = ''
  startHour.value = 0
  startMinute.value = 0
  endHour.value = 23
  endMinute.value = 59
}

const isFormValid = ref(false)

interface FormErrors {
  startDate?: string
  startDateTime?: string
  endDate?: string
  endDateTime?: string
}

const errors = ref<FormErrors>({})

const validateForm = () => {
  errors.value = {}

  if (!startDate.value) errors.value.startDate = 'Entrez une date de début.'
  if (!endDate.value) errors.value.endDate = 'Entrez une date de fin.'

  return Object.keys(errors.value).length === 0
}

const _fromDate = computed(() => {
  return dayjs(
    `${dayjs(startDate.value).format('YYYY-MM-DD')} ${
      Number(startHour.value) < 10
        ? '0' + Number(startHour.value)
        : Number(startHour.value)
    }:${
      Number(startMinute.value) < 10
        ? '0' + Number(startMinute.value)
        : Number(startMinute.value)
    }`,
    'YYYY-MM-DD HH:mm',
  )
})

const _toDate = computed(() => {
  return dayjs(
    `${dayjs(endDate.value).format('YYYY-MM-DD')} ${
      Number(endHour.value) < 10
        ? '0' + Number(endHour.value)
        : Number(endHour.value)
    }:${
      Number(endMinute.value) < 10
        ? '0' + Number(endMinute.value)
        : Number(endMinute.value)
    }`,
    'YYYY-MM-DD HH:mm',
  )
})

const toggleVerifyDialog = async () => {
  if (!validateForm()) return false

  await vacationValidate()

  isDeleting.value = false
  alertTitle.value = isEditing.value
    ? t('dashboard.time-slot.dialogVacation.confirmUpdateVacation')
    : t('dashboard.time-slot.dialogVacation.confirmCreateVacation')
  minTitle.value = !isEditing.value
    ? t('dashboard.time-slot.dialogVacation.confirmMinTitle')
    : ''
  alertDescription.value = verifyVacationText.value
  displayAlert.value = true
}

const vacationValidate = async () => {
  if (!validateForm()) return false
  try {
    loadingConfirmBtn.value = true

    const vacation: VacationPostOrPutParams = {
      startDate: _fromDate.value.toISOString(),
      endDate: _toDate.value.toISOString(),
    }

    let verifyVacation: ApiResponse<VerificationMessage>
    if (isEditing.value) {
      // case edition
      try {
        verifyVacation = await httpClient.put(
          `/vacation/${vacationId.value}/validate`,
          vacation,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        )
      } catch (error) {
        verifyVacationText.value = error.response.data.message
      }
    } else {
      // case creation
      try {
        verifyVacation = await httpClient.post('/vacation/validate', vacation, {
          headers: { 'Content-Type': 'application/json' },
        })
        verifyVacationText.value = verifyVacation.data.message
      } catch (error) {
        verifyVacationText.value = error.response.data.message
        warningColor.value = 'red'
      }
    }
  } catch (_) {}
  loadingConfirmBtn.value = false
}

const handleClose = () => {
  reset()
}

const startYear: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.year'),
)
const startMonth: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.month'),
)
const startDay: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.day'),
)
const endYear: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.year'),
)
const endMonth: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.month'),
)
const endDay: Ref<string | number> = ref(
  t('dashboard.time-slot.dialogVacation.day'),
)
// Number e.g. 23 or 0
const startHour: Ref<string | number> = ref(0)
// Number e.g. 59 or 0
const startMinute: Ref<string | number> = ref(0)
const endHour: Ref<string | number> = ref(23)
const endMinute: Ref<string | number> = ref(59)

const checkFromDateFieldsEmpty = () =>
  typeof startYear.value !== 'number' ||
  typeof startMonth.value !== 'number' ||
  typeof startDay.value !== 'number'

const checkToDateFieldsEmpty = () =>
  typeof endYear.value !== 'number' ||
  typeof endMonth.value !== 'number' ||
  typeof endDay.value !== 'number'

const fromDate = computed(() => {
  if (checkFromDateFieldsEmpty()) {
    return dayjs('invalid date')
  } else {
    return dayjs(
      `${startYear.value}-${startMonth.value}-${startDay.value} ${
        Number(startHour.value) < 10
          ? '0' + Number(startHour.value)
          : Number(startHour.value)
      }:${
        Number(startMinute.value) < 10
          ? '0' + Number(startMinute.value)
          : Number(startMinute.value)
      }`,
      'YYYY-MM-DD HH:mm',
    )
  }
})

const toDate = computed(() => {
  if (checkToDateFieldsEmpty()) {
    return dayjs('invalid date')
  } else {
    return dayjs(
      `${endYear.value}-${endMonth.value}-${endDay.value} ${
        Number(endHour.value) < 10
          ? '0' + Number(endHour.value)
          : Number(endHour.value)
      }:${
        Number(endMinute.value) < 10
          ? '0' + Number(endMinute.value)
          : Number(endMinute.value)
      }`,
      'YYYY-MM-DD HH:mm',
    )
  }
})

const checkInvalidDates = () => {
  return !(
    fromDate.value.isValid &&
    fromDate.value.isValid() &&
    toDate.value.isValid()
  )
}
const validDates = ref(false)
const errorDate = ref(false)
watch(
  [
    startYear,
    startMonth,
    startDay,
    startHour,
    startMinute,
    endYear,
    endMonth,
    endDay,
    endHour,
    endMinute,
  ] as unknown as any[],
  (
    [
      newstartYear,
      newstartMonth,
      newstartDay,
      newstartHour,
      newstartMinute,
      newendYear,
      newendMonth,
      newendDay,
      newendHour,
      newendMinute,
    ],
    [
      oldstartYear,
      oldstartMonth,
      oldstartDay,
      oldstartHour,
      oldstartMinute,
      oldendYear,
      oldendMonth,
      oldendDay,
      oldendHour,
      oldendMinute,
    ],
  ) => {
    startYear.value = newstartYear
    startMonth.value = newstartMonth
    startDay.value = newstartDay
    startHour.value = newstartHour
    startMinute.value = newstartMinute
    endYear.value = newendYear
    endMonth.value = newendMonth
    endDay.value = newendDay
    endHour.value = newendHour
    endMinute.value = newendMinute
    verifyAndValidate()
  },
)
const verifyAndValidate = () => {
  validDates.value = !checkInvalidDates()
  errorDate.value = !!checkErrorDate()
  if (errorDate.value) {
    callVerifyVacation()
  }
}
const checkErrorDate = () => {
  const validDates = !checkInvalidDates()
  if (
    validDates &&
    (fromDate.value.isAfter(toDate.value) ||
      fromDate.value.isSame(toDate.value))
  ) {
    return t('dashboard.time-slot.dialogVacation.errorDate')
  }
  return false
}

const rdvInTheseDates = ref(false)
const verifyVacationText = ref('')
const showAlert = computed(() => {
  if (verifyVacationText.value) return true
  else return !!checkErrorDate()
})

const alertText = computed(() => {
  const errorDates = checkErrorDate()
  if (rdvInTheseDates.value)
    return t('dashboard.time-slot.dialogVacation.alert')
  else if (errorDates) return String(errorDates)
  else if (verifyVacationText.value) return verifyVacationText.value
  else return ''
})

interface VacationPostOrPutParams {
  startDate: string
  endDate: string
}

interface VerificationMessage {
  message: string
}

const httpClient = createHttpClient()

const callVerifyVacation = async () => {
  if (!checkInvalidDates()) {
    try {
      loadingConfirmBtn.value = true
      const vacation: VacationPostOrPutParams = {
        startDate: fromDate.value.toISOString(),
        endDate: toDate.value.toISOString(),
      }
      let verifyVacation: ApiResponse<VerificationMessage>
      if (isEditing.value) {
        // case edition
        try {
          verifyVacation = await httpClient.put(
            `/vacation/${vacationId.value}/validate`,
            vacation,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
        } catch (error) {
          verifyVacationText.value = error.response.data.message
        }
      } else {
        // case creation
        try {
          verifyVacation = await httpClient.post(
            '/vacation/validate',
            vacation,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
          verifyVacationText.value = verifyVacation.data.message
        } catch (error) {
          verifyVacationText.value = error.response.data.message
        }
      }
    } catch (error) {
      // verifyVacationText.value = error
      // MessageService.error(t('common.error.errorHasOccurred'))
    }
    loadingConfirmBtn.value = false
  }
}

interface VacationDTOResponse extends VacationPostOrPutParams {
  id: string
}
const loadingConfirmBtn = ref(false)

const handleConfirmVacation = async () => {
  loadingConfirmBtn.value = true
  try {
    const vacation: VacationPostOrPutParams = {
      startDate: _fromDate.value.toISOString(),
      endDate: _toDate.value.toISOString(),
    }
    let validatedVacation: ApiResponse<VacationDTOResponse>
    if (isEditing.value) {
      // vacation edition
      validatedVacation = await httpClient.put(
        `/vacation/${vacationId.value}/update`,
        vacation,
      )
      if (validatedVacation.status !== 200) {
        throw Error(JSON.stringify(validatedVacation))
      }
      MessageService.success(
        isEditing.value
          ? t(
              'dashboard.time-slot.dialogVacation.successVacactionValidateUpdate',
            )
          : t('dashboard.time-slot.dialogVacation.successVacactionValidate'),
      )
    } else {
      // vacation creation
      validatedVacation = await httpClient.post('/vacation/create', vacation)

      MessageService.success(
        t('dashboard.time-slot.dialogVacation.successVacactionValidate'),
      )
    }

    await init()
  } catch (error) {
    MessageService.error(
      t('dashboard.time-slot.dialogVacation.errorVacactionValidate'),
    )
  }
  loadingConfirmBtn.value = false
  reset()
}

const loadingDelete = ref(false)
const isDeleting = ref(false)

const openDeleteConfirm = () => {
  isDeleting.value = true
  alertTitle.value = t(
    'dashboard.time-slot.dialogVacation.confirmDeletionTitle',
  )
  alertDescription.value = t(
    'dashboard.time-slot.dialogVacation.confirmDeletionMessage',
  )
  displayAlert.value = true
}

const confirmation = () => {
  if (isDeleting.value) {
    handleDeleteVacation()
    return
  }
  handleConfirmVacation()
}

const router = useRouter()
const init = async () => {
  const { initTimeSlots } = useTimeSlotStore()
  const { initAppointments } = usePractitionerAppointmentStore()
  if (router.currentRoute.value.name === 'DashboardTimeSlot') {
    await initTimeSlots()
  }
  if (router.currentRoute.value.name === 'DashboardAppointment') {
    await initAppointments()
  }
}

const handleDeleteVacation = async () => {
  loadingDelete.value = true
  try {
    await httpClient.delete('/vacation/' + vacationId.value + '/delete')

    MessageService.success(
      t('dashboard.time-slot.dialogVacation.successDeleteVacaction'),
    )
    await init()
  } catch (error) {
    MessageService.error(t('common.error.errorHasOccurred'))
  }
  loadingDelete.value = false
  reset()
}

const menu = ref(false)
const time = ref(null)
</script>

<style lang="scss">
.close-icon {
  position: absolute;
  right: 0;
}
.red-icon:deep() i {
  color: red;
}
.datetime-selector {
  display: grid;
  grid-gap: 12px;
  grid-template-areas:
    'a b c c c c c c c c'
    'a b d d d d d d d d';
}
.a {
  grid-area: a;
  width: 20px;
}
.b {
  width: 20px;
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
}

.v-menu > .v-overlay__content {
  background: white !important; /* Remplacez 'red' par la couleur souhaitée */
}
</style>
