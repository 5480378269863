<template>
  <v-form
    class=""
    @update:modelValue="emit('update:is-valid', $event)"
    ref="profileAddr"
  >
    <div>
      <!-- <v-icon icon="mdi-account-multiple-outline" /> -->
      <label for="" class="custom-text-gray">{{
        t('dashboard.time-slot.dialogTimeSlot.selectProfile')
      }}</label>
      <v-combobox
        :rules="required"
        density="compact"
        class="mt-1"
        v-model="selectedProfiles"
        :items="profileStore.profiles"
        multiple
        item-title="mainDiscipline.name"
        item-value="id"
        placeholder="Profils"
        chips
        chips-rounded
        clearable
        :key="mainDiscipline"
        @update:modelValue="handleSelectProfiles"
        hide-details="auto"
      ></v-combobox>
    </div>

    <div class="mt-2">
      <!-- <v-icon icon="mdi-map-marker-outline" /> -->
      <label for="" class="custom-text-gray">{{
        t('dashboard.time-slot.dialogTimeSlot.selectAddress')
      }}</label>
      <v-combobox
        :rules="required"
        density="compact"
        class="mt-1"
        v-model="selectedAddresses"
        :items="addressStore.addresses"
        multiple
        item-title="formattedAddress"
        item-value="id"
        placeholder="Selectionner une adresse"
        hide-selected
        variant="outlined"
        clearable
        @update:modelValue="handleSelectAddresses"
        ref="addressCombobox"
        :key="addresses"
        hide-details="auto"
      ></v-combobox>
    </div>
  </v-form>
</template>

<script lang="ts" setup>
import { ref, nextTick, onMounted } from 'vue'
import { useUserStore } from '@/store/user/userStore'
import { useDialogTimeSlotStore } from '@/store/timeslot/dialogTimeSlotStore'
import { formatAddress } from '@/utils/formatAddress'
import { useAddressStore } from '@/store/address/addressStore'
import { useProfileStore } from '@/store/profile/profileStore'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const required = [
  value => (value && value.length ? true : 'Ce champ est requis'),
]

const mainDiscipline = ref(0)
const addresses = ref(0)

// Props for initial values
const props = defineProps({
  initialProfiles: {
    type: Array,
    default: () => [],
  },
  initialAddresses: {
    type: Array,
    default: () => [],
  },
})
const profileAddr = ref(null)

const userStore = useUserStore()
const addressStore = useAddressStore()
const profileStore = useProfileStore()
const dialogStore = useDialogTimeSlotStore()
const emit = defineEmits([
  'update:profiles',
  'update:addresses',
  'update:is-valid',
])

const selectedProfiles = ref([])
const selectedAddresses = ref([])
const addressCombobox = ref(null)

// Profile selection logic
const handleSelectProfiles = profiles => {
  dialogStore.selectedProfiles = profiles
  dialogStore.saveToLocalStorage(
    profiles[0],
    dialogStore.selectedAddresses[0],
    dialogStore.selectedProfiles,
    dialogStore.selectedAddresses,
  )
  emit('update:profiles', profiles)
}

// Address selection logic
const handleSelectAddresses = addresses => {
  if (addresses.length > 1) {
    selectedAddresses.value = [addresses[addresses.length - 1]]
  } else {
    selectedAddresses.value = addresses
  }
  dialogStore.selectedAddresses = selectedAddresses.value
  dialogStore.saveToLocalStorage(
    dialogStore.selectedProfiles[0],
    selectedAddresses.value[0],
    dialogStore.selectedProfiles,
    selectedAddresses.value,
  )
  emit('update:addresses', selectedAddresses.value)

  // Fermer la liste après sélection
  nextTick(() => {
    if (addressCombobox.value) {
      addressCombobox.value.blur()
    }
  })
}

// Computed to format address
const formattedAddress = address => {
  return formatAddress(address, true)
}

// Initialize the component with the provided values on mount
onMounted(() => {
  mainDiscipline.value++
  addresses.value++
  selectedProfiles.value = props.initialProfiles
  selectedAddresses.value = dialogStore.selectedAddresses
})

const validate = async () => {
  if (!profileAddr.value) return
  const valid = await profileAddr.value.validate()
  emit('update:is-valid', valid)
  return valid
}

defineExpose({
  validate,
})
</script>

<style scoped>
/* .v-combobox {
  width: 100%;
  margin-bottom: 16px;
  min-width: 400px;
  max-width: 100%;
  height: 40px;
} */

.custom-text-gray {
  color: #616161 !important;
}

/* :deep(.v-combobox .v-field__input) {
  min-height: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
} */

:deep(.v-text-field .v-field) {
  border-radius: 10px !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

:deep(.d-flex .v-combobox .v-chip.v-chip--density-default) {
  border-radius: 22px !important;
}
</style>
